"use client";

import { useEffect } from "react";
import { logAnalyticsEvent } from "~/libs/3rd-party/firebase/FirebaseAnalyticsProvider";
import "./error.css";
import * as Sentry from "@sentry/nextjs";
import { env } from "~/env.mjs";
process.env.NODE_ENV === "production" && Sentry.init({
  dsn: env.NEXT_PUBLIC_SENTRY_DSN,
  tracesSampleRate: 1.0
});
export default function Error({
  error,
  reset
}: {
  error: Error & {
    digest?: string;
  };
  reset: () => void;
}) {
  useEffect(() => {
    if (error.digest) {
      console.error(error.digest);
    }
    console.error(error);
    logAnalyticsEvent("error", {
      error: error.message,
      digest: error.digest
    });
    Sentry.captureException(error, {
      tags: {
        digest: error.digest
      }
    });
  }, [error]);
  return <div onKeyUp={() => {
    reset();
  }} className="error-main" data-sentry-component="Error" data-sentry-source-file="error.tsx">
      <div className="notfound">
        <div className="centered">
          <span className="inverted">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          &nbsp;
        </div>
        <div className="centered">
          <span className="inverted">&nbsp;5&nbsp;0&nbsp;0&nbsp;</span>
          <span className="shadow">&nbsp;</span>
        </div>
        <div className="centered">
          <span className="inverted">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span className="shadow">&nbsp;</span>
        </div>
        <div className="centered">
          &nbsp;
          <span className="shadow">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </div>
        <div className="row">&nbsp;</div>
        <div className="row">
          A fatal exception has occurred at C0DE: {error.message}
        </div>
        <div className="row">
          Sometimes refreshing the page solves the issue.
        </div>
        <div className="row">&nbsp;</div>
        <div className="row">* Press any key to refresh the page.</div>

        <div className="row">&nbsp;</div>
        <div className="centered">
          Press any key to refresh the page...
          <span className="blink">&#9608;</span>
        </div>
      </div>
    </div>;
}